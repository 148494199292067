import React, { useContext, createContext, useState } from 'react';

const SettingsContext = createContext({
  darkMode: true,
  setDarkMode: () => {},
});

export const SettingsProvider = ({ children }) => {
  const [darkMode, setDarkMode] = useState(true);

  return (
    <SettingsContext.Provider value={{ setDarkMode, darkMode }}>
      {children}
    </SettingsContext.Provider>
  );
};
export const useSettings = () => {
  const { darkMode, setDarkMode } = useContext(SettingsContext);

  const toggleDarkMode = () => {
    const newMode = !darkMode;
    localStorage.setItem('skendzic.dev:dark-mode', newMode);
    setDarkMode(newMode);
  };
  return {
    darkMode,
    toggleDarkMode,
  };
};
