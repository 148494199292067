module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Skendzic.dev | FrontEnd developer","short_name":"Skendzic.dev","start_url":"/","background_color":"black","theme_color":"black","display":"minimal-ui","icon":"src/images/favicon-16x16.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"025609f7f582be4dfe4357de51836853"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
