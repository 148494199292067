import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles';
import theme from './src/styles/theme';
import 'prismjs/themes/prism-okaidia.css';
import './src/styles/overrides.scss';
import { SettingsProvider } from './src/context/settings';

export const wrapRootElement = ({ element }) => {
  return (
    <SettingsProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {element}
      </ThemeProvider>
    </SettingsProvider>
  );
};
export const onInitialClientRender = () => {
  return window.onload = () => {
    const loader = document.getElementById('___loader')
    loader.style.display = 'none';
  }
}
