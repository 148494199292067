const palette = {
  background: {
    default: 'var(--background)',
  },
  text: {
    primary: 'var(--primary)',
    secondary: 'var(--text-muted)',
  },
  primary: {
    light: 'var(--primary-light)',
    main: 'var(--primary)',
    dark: 'var(--primary-dark)',
    contrastText: '#fff',
  },
  secondary: {
    light: 'var(--secondary)',
    main: 'var(--secondary)',
    dark: 'var(--secondary-dark)',
    contrastText: '#fff',
  },
};

export default palette;
