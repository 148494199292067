import palette from './palette';
import "@fontsource/roboto/latin-ext.css"

const typography = typography => ({
  fontFamily: 'Roboto',
  fontSize: 16,
  h1: {
    fontSize: typography.pxToRem(48),
    lineHeight: 1.2,
    fontWeight: 800,
  },
  h2: {
    fontSize: typography.pxToRem(36),
    lineHeight: 1.2,
    textDecoration: 'none',
    color: palette.text.primary,
    fontWeight: 700,
  },
  h3: {
    fontSize: typography.pxToRem(20),
    lineHeight: 1.2,
    fontWeight: 300,
  },
  body1: {
    fontSize: typography.pxToRem(16),
    lineHeight: 1.5,
    fontWeight: 500,
    gridColumn: 2,
  },
  body2: {
    fontSize: typography.pxToRem(16),
    lineHeight: 1.2,
    fontWeight: 500,
    gridColumn: 2,
  },
});

export default typography;
